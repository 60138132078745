import { useEffect, useState } from 'react'
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { Marker } from 'react-leaflet/Marker'
import { Popup } from 'react-leaflet/Popup'
import { Polyline } from 'react-leaflet/Polyline'
import  Card from 'react-bootstrap/Card';
import './maps.css'
import L, { map } from "leaflet"
import stopIcon from "../shutdown.png"
import userIcon from "../business-woman.png"
import startIcon from "../play-button-green.png"
import busIcon from "../bus-symbol.png"
import busIconStop from '../bus-stop.png'
import utf8 from 'utf8'

//import atob from "atob"
import "leaflet-gpx"
import {Buffer} from 'buffer';
import axios from 'axios'

import moment from 'moment';

import XMLParser from 'react-xml-parser';

import { ProcesarGPX } from '../functional/ProcesarGPX'
import { DetailService } from './DetailService'

const APIKEYGH = "826517fe-5894-4043-bcd4-8c13418615ae";

function getIconBusStop (__iconSize)
{
    return L.icon({
        iconUrl: busIconStop,
        iconSize: __iconSize
        });
}

function getIcon(__iconSize)
{
   return L.icon({
            iconUrl: stopIcon,
            iconSize: __iconSize
            });
}

function getIconBus(__iconSize)
{
   return L.icon({
            iconUrl: busIcon,
            iconSize: __iconSize
            });
}

function getIconUser(__iconSize)
{
   return L.icon({
            iconUrl: userIcon,
            iconSize: __iconSize
            });
}
function getIconStart(__iconSize)
{
   return L.icon({
            iconUrl: startIcon,
            iconSize: __iconSize
            });
}

export function ContainerMap({position, orden, information}) {

    console.log('la informacion es ',information)
   // const pos = [information.informacionUsuario.latitud, position.informacionUsuario.longitud]

    const pos = (information.informacionUsuario ? 
                                                 [information.informacionUsuario.latitud, information.informacionUsuario.longitud]
                                                :[]);
    
    let centro = null;
    if (information.status == 200) 
    {
        centro = (information.informacionUnidad ?
                                                   [information.informacionUnidad.latitud, information.informacionUnidad.longitud]
                                                   : 
                                                   [information.paradaRecomendada.latitud, information.paradaRecomendada.longitud ]);
    }
    const limeOptions = { color: 'red' }
    const routeOptions = { color: 'blue', dashArray: '7, 7', dashOffset: '5' }
    const [start, setStart] = useState(null)
    const [end, setEnd] = useState(null)
    const [lines, setLines] = useState([])
    const [busPosition, setBusPosition] = useState(null)
    const [paradas, setParadas] = useState([])
    const [ pointsUser, setPointsUser ] = useState(null)
    let puntosUser = []
  
    const corregirFecha = (informacion) => {
        let dateString = informacion.informacionUnidad.fechaHora
       // dateString = dateString.slice(0, (dateString.length - 3))
        const last = moment(dateString, "M/D/YYYY hh:mm:ss a").subtract({ hours: 3})
        
        const now = moment()

       // console.log('POSICION ', last.format('D/M/YYYY hh:mm:ss'), '     ACTUAL ', now.format('D/M/YYYY hh:mm:ss'))
        const diff = (now.diff(last, 'minutes'))
        if (diff == 0)
        {
            return now.diff(last, 'second')+ ' Segundos';
        }

        if (diff < 2)
        {
            return diff + ' Minuto'
        }
        return diff + ' Minutos   ' //+ last.format('DD/MM/YYYY - hh:mm:ss') +'     '+now.format('DD/MM/YYYY - hh:mm:ss')
    }

    const horarioParada = (stop) => {
            const horarios = stop.getElementsByTagName('name')[0].value.slice(-14).split('/')
            const salida = moment(orden.hsalida, 'HH:mm:ss')
            const llegada = moment(orden.hllegada, 'HH:mm:ss')
            let hour
            //console.log(horarios)

            if (horarios[0] != '9999')
            {
                hour = moment(horarios[0], "HHmm")
                if (hour.isBetween(salida, llegada))
                {
                    return hour.format('HH:mm')
                }
                else if (horarios[1] != '9999')
                {
                    hour = moment(horarios[1], "HHmm")
                    if (hour.isBetween(salida, llegada))
                    {
                        return hour.format('HH:mm')
                    }
                    else if (horarios[2] != '9999')
                    {
                        hour = moment(horarios[2], "HHmm")
                        if (hour.isBetween(salida, llegada))
                        {
                            return hour.format('HH:mm')
                        }
                    }
                }
            }
            else if (horarios[1] != '9999')
            {
                hour = moment(horarios[1], "HHmm")
                if (hour.isBetween(salida, llegada))
                {
                    return hour.format('HH:mm')
                }
                else if (horarios[2] != '9999')
                {
                    hour = moment(horarios[2], "HHmm")
                    if (hour.isBetween(salida, llegada))
                    {
                        return hour.format('HH:mm')
                    }
                }
            }
            else if (horarios[2] != '9999')
            {
                hour = moment(horarios[2], "HHmm")
                if (hour.isBetween(salida, llegada))
                {
                    return hour.format('HH:mm')
                }
            }
            
            return ''
    }

    const getPoints = async () => {

                                    if (information.status == 200)
                                    {
                                        if (information.informacionUnidad)
                                        {
                                            setBusPosition([
                                                information.informacionUnidad.latitud, 
                                                information.informacionUnidad.longitud
                                            ]);
                                        }
                                        else
                                        {
                                            setBusPosition(null)
                                        }

                                        const { puntos, init, fin, paradas } = ProcesarGPX(information.gpx, orden)
                                        setLines(puntos);
                                        setStart(init); 
                                        setEnd(fin);
                                        setParadas(paradas);
                                    }
                            

                                if (information.paradaRecomendada)
                                {
                                    let latitud = null 
                                    let longitud = null
                                    if (information.paradaRecomendada)
                                    {
                                            latitud = information.paradaRecomendada.latitud
                                            longitud = information.paradaRecomendada.longitud                                   
                                    }

                                        if (latitud && longitud)
                                        {
                                            console.log('buscando ruta ')
                                            setPointsUser(null)
                                            puntosUser = []
                                             const url = `https://graphhopper.com/api/1/route?key=${APIKEYGH}&point=${pos[0]},${pos[1]}&point=${latitud},${longitud}&points_encoded=false&instructions=false&profile=foot`
                                            await axios.get(url)
                                                        .then(({data}) => {
                                                                            console.log('graphhopper ', data)
                                                                            const { paths } = data
                                                                            if (paths)
                                                                            {
                                                                                const detail = paths[0]
                                                                                const { points } = detail
                                                                                const {coordinates} = points
                                                                                let puntos = []
                                                                                coordinates.map(x => {                                                                                                        
                                                                                                        puntos.push([x[1], x[0]])
                                                                                                    })
                                                                                setPointsUser(puntos)
                                                                                console.log('los puntos  son ', pointsUser)
                                                                            }
                                                                            
                                                        })
                                                        .catch(e => {
                                                            // Capturamos los errores
                                                        })
                                        }
                                }

    }
    useEffect(() => {
                        getPoints()

    }, [])

    return(
            <>
                <Card className='mt-2 mb-2 ml-4'>
                    <div className='row mx-2 mt-2'>
                        <div className='col-lg-6 col-sm-12'>
                            Parada mas cercana:  
                            <span className='text-muted'>
                                    { information.paradaRecomendada ?
                                            information.paradaRecomendada.nombre
                                                
                                            :
                                                '' }
                            </span>
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            Tiempo de arribo del Bus a la parada :  
                            <span className='text-muted'>
                                    {
                                        (information.paradaRecomendada) ?
                                                                            information.paradaRecomendada.tiempoEstimadoArribo?
                                                                                                                                information.paradaRecomendada.tiempoEstimadoArribo
                                                                                                                              :
                                                                                                                              ''
                                                                        :

                                                                        ''
                                    }   
                            </span>

                        </div>
                    </div>
                    <div className='row mx-2 mb-2'>

                    </div>
                </Card>
            <div id="map" className='mt-4 mb-4'>

                <MapContainer center={centro} zoom={15} scrollWheelZoom={true}>
                    <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        start?
                        <Marker position={start} icon={ getIconStart(20)}>
                            <Popup>
                                Inicio del servicio
                            </Popup>
                        </Marker>
                        :<></>
                    }

                    {
                        pos?
                            <Marker position={pos} icon={getIconUser(20)}>
                                <Popup>
                                    Mi Ubicacion
                                </Popup>
                            </Marker>
                            :
                            <></>
                    }
                    {
                        busPosition?
                            <Marker position={[
                                information.informacionUnidad.latitud, 
                                information.informacionUnidad.longitud
                            ]} icon={getIconBus(20)}>
                                <Popup>
                                    { orden.interno }
                                </Popup>
                            </Marker>
                            :
                            <></>
                    }
                    {
                        end?
                        <Marker position={end} icon={getIcon(20)}>
                            <Popup>
                               
                            </Popup>
                        </Marker>
                        :<></>
                    }
                    {
                        
                        paradas.map(x => {
                            return <Marker key={Math.random()} position={[x.lat, x.lon]} icon={getIconBusStop(25)}>
                                <Popup>
                                    { utf8.decode(x.name) }
                                </Popup>
                            </Marker>
                        })
                    }
                        <Polyline pathOptions={limeOptions} positions={lines} />
                    {
                        pointsUser ? <Polyline pathOptions={routeOptions} positions={pointsUser} /> : ''
                    }
                    
                </MapContainer>
            </div>
            </>
            )
}
import  Navbar  from 'react-bootstrap/Navbar';
import  Container from "react-bootstrap/Container";
import Logo from '../../../src/logo.png'
import Image from 'react-bootstrap/Image'

import Context from "../../context/PositionContext";
import { useContext } from 'react';

export default function AppBar() {

    const {position, enabled} = useContext(Context)

    return (
                <Navbar bg="white">
                <Container>
                <Navbar.Brand href="/pro">
                    <Image
                    src={Logo}
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                </Container>
                </Navbar>
            );
}

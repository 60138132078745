import { useState, useContext, useEffect } from "react";

import Form from "react-bootstrap/Form";
import { getPositionClient } from "../services/servicios";
import { usePosition } from "./hook/usePosition";

function camelize(str) {

    return str.toLowerCase().replace(/(?:^|\s)\w/g, function(match) {
        return match.toUpperCase();
    });

}

export default function ListOfServices({ data, selected, findService, enabled }) {
  const { position } = usePosition();

  useEffect(() => {
   // console.log("Calculating...", position);
  }, []);

  const handleChange = async ({ target }) => {
    if (target.value != "0") {
      selected(true);
      findService(target.value);
    } else {
      findService(null);
      selected(false);
    }
  };

  return (
    <>
      <Form.Label>Servicios de entrada</Form.Label>
      <Form.Select
        aria-label="Default select example"
        onChange={handleChange}
        className="mb-4"
        style={ {'fontFamily' : 'arial', 'fontSize' : '10pt'} }
        disabled={!enabled}
      >
        <option value="0">Seleccione un servicio</option>
        {data.map((elem) => {
          return (
            <option value={elem.id_externo} key={elem.id_externo}>
              
              { camelize(elem.servicio) } 
            </option>
          );
        })}
      </Form.Select>
    </>
  );
}

import  Card from 'react-bootstrap/Card';
import reload from '../reload-sync-icon.png'
import driver from '../driver.png'
import bus from '../bus-symbol.png'
import hour from '../hour.png'
import './detail.css'
import { useEffect, useState } from 'react';
import moment from 'moment';

export function DetailService({orden, findService}) {

    const [now, setNow] = useState(null)
    const ord = orden
    const handleClick = () => {
                                findService(ord.iOrdenTrabajo)
                                updateNow()
                                
    }
    
    
    const updateNow = () => {
        setNow(moment())
    }

    useEffect(() => {
                        updateNow();
    }, [])


    return(
            
                <Card className='mt-2 ml-4 detail'>
                    <div className='row mx-2'>
                        <div className='col-lg-3 col-sm-12 align-left'>
                            
                                <img width={15} height={15} src={ bus } className='align-middle'/>: <span className='detail align-middle h5 pl-3'>{ orden.interno }</span>
                              
                        </div>
                        <div className='col-lg-3 col-sm-12 align-left'>
                                
                                <img width={15} height={15} src={ hour } className='align-middle'/>: <span className='detail align-middle h5 pl-3'>{ orden.hora_salida }</span>
                            
                        </div>
                    </div>
                    <div className='row mx-2'>
                        <div className='col-lg-3 col-sm-12 align-left text-muted mt-2'>
                            Fecha Consulta: 
                            {
                                now ?
                                    '  '+now.format('D/M/YYYY - HH:mm:ss')
                                    :
                                    ''
                            }
                             
                        </div>
                    </div>
                    <div className='row mx-2 mb-4 pt-2'>
                        
                        <div className='col-lg-3 col-sm-12 ml-2 pt-2'><a onClick={handleClick} href="#" className='text-right btn btn-sm btn-success'>Recargar</a></div>
                    </div>
                </Card>
            )
}
import { useState, useContext, useEffect } from "react";

import ProgressBar from "react-bootstrap/ProgressBar";
import Card from "react-bootstrap/Card";
import ListOfServices from "./ListOfServices";
import { useServices } from "./hook/useServices";
import { ContainerMap } from "./ContainerMap";

import { getDataFromBack } from "../services/servicios";

import { usePosition } from "./hook/usePosition";
import { DetailService } from "./DetailService";

export default function SelectServices() {
  const { position, getPosition, awaitPosition, errorPosition } = usePosition();
  const { services, loading } = useServices();
  const [orden, setOrden] = useState(null);
  const [selected, setSelected] = useState(null);
  const [enabledSelect, setEnabledSelected] = useState(true);
  const [information, setInformation] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState(null);
  const [busPosition, setBusPosition] = useState(null);
  const [messageError, setMessageError] = useState(null)


  const findService = (order) => {

    
    setInformation(null);
    setOrden(null);
    setCargando(true);
    setError(null);
    setEnabledSelected(false)
    console.log('LOS SEVICIOS SON ', services)
    if ((order) && (!awaitPosition) && (!errorPosition)) {

      let qry = null
      
      services.find((x) => {
        if (x.id_externo == order) {
          setOrden(x);
          qry = {
                    idServicio: x.id_externo,
                    interno: x.dominio,
                    idOrdenTrabajo : x.id_externo,
                    posicionPasajero: {
                      latitud: position.lat,
                      longitud: position.lng,
                    },
                };
          }
      });

      if (qry)
      {
          console.log('data enviada al endpoint ', qry)

          getDataFromBack(qry)
                  .then(({ data }) => {
                    console.log('la respuesta es ', data)
                    if (data.status == 200)
                    {
                        if (data.informacionUnidad) {
                                                      setBusPosition([
                                                                      data.informacionUnidad.latitud,
                                                                      data.informacionUnidad.longitud,
                                                                    ]);
                                                      setInformation(data);
                                                      setError(false)
                        }
                        else
                        {
                            setBusPosition(null);
                            setInformation(data);
                            setError(false)
                        }
                    }
                    else if (data.status == 301)
                    {
                      setInformation(data)
                      setMessageError(data.message);
                      setError(true)
                    }
                    else
                    {
                          setMessageError('Inconvenientes con el recorrido, comuniquese con mesa de ayuda (11 6842 1800)');
                          setInformation(null);
                          setError(true)
                    }
                    
                    setCargando(false);
                    setEnabledSelected(true)
                  })
                  .catch((err) => {

                    const { response } = err;
                    setInformation(null);
                    setCargando(false);
                    setError(response.data.message);
                    setEnabledSelected(true)
                  });
          }
    } 
    else 
    {
      setCargando(false);
    }
  };

  useEffect(() => {
    //console.log("posicion ", position);
  }, []);

  return (
    <>
      {loading ? 
                  <ProgressBar striped variant="danger" now={100} className="mt-4" />
                : (
        <Card className="mt-1">
          <Card.Body>
            <ListOfServices
              findService={findService}
              data={services}
              selected={setSelected}
              position={position}
              enabled = {enabledSelect}
            />
            {
                  cargando ?
                            (<>
                                <span className="spinner-border spinner-border-sm mt-3 mr-3 pr-3" role="status" aria-hidden="true"></span>
                                <span className="sr-only mt-3 ml-2">Obteniendo informacion...</span>
                            </>)
                           :
                           information ?
                                        information.status == 200 ?
                                                                  (
                                                                    <>
                                                                        <DetailService 
                                                                                      findService={findService} 
                                                                                      orden={orden}/> 

                                                                        <ContainerMap
                                                                                    information={information}
                                                                                    position={position}
                                                                                    orden={orden}
                                                                                  />
                                                                    </>
                                                                  )
                                                                  :
                                                                  (
                                                                    <div className="text-muted m-4 text-center">
                                                                            { messageError }
                                                                    </div>
                                                                    )                                  : 
                                  <></>


            }
          </Card.Body>
        </Card>
      )}
    </>
  );
}

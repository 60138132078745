import atob from "atob"
import XMLParser from 'react-xml-parser';
import moment from 'moment';

export function ProcesarGPX(gpxfile, orden) {

    const horarioParada = (stop) => {
        const horarios = stop.getElementsByTagName('name')[0].value.slice(-14).split('/')
        const salida = moment(orden.hsalida, 'HH:mm:ss')
        const llegada = moment(orden.hllegada, 'HH:mm:ss')
        let hour
        //console.log(horarios)

        if (horarios[0] != '9999')
        {
            hour = moment(horarios[0], "HHmm")
            if (hour.isBetween(salida, llegada))
            {
                return hour.format('HH:mm')
            }
            else if (horarios[1] != '9999')
            {
                hour = moment(horarios[1], "HHmm")
                if (hour.isBetween(salida, llegada))
                {
                    return hour.format('HH:mm')
                }
                else if (horarios[2] != '9999')
                {
                    hour = moment(horarios[2], "HHmm")
                    if (hour.isBetween(salida, llegada))
                    {
                        return hour.format('HH:mm')
                    }
                }
            }
        }
        else if (horarios[1] != '9999')
        {
            hour = moment(horarios[1], "HHmm")
            if (hour.isBetween(salida, llegada))
            {
                return hour.format('HH:mm')
            }
            else if (horarios[2] != '9999')
            {
                hour = moment(horarios[2], "HHmm")
                if (hour.isBetween(salida, llegada))
                {
                    return hour.format('HH:mm')
                }
            }
        }
        else if (horarios[2] != '9999')
        {
            hour = moment(horarios[2], "HHmm")
            if (hour.isBetween(salida, llegada))
            {
                return hour.format('HH:mm')
            }
        }
        
        return ''
}
    var file = atob(gpxfile)
    var xml = new XMLParser().parseFromString(file);    
    var points = xml.getElementsByTagName('trkpt');
    var busStop = xml.getElementsByTagName('wpt');

    let paradas = [];
    busStop.map(x => {
                        
                        let name = x.getElementsByTagName('name')[0].value
                        let i = name.indexOf('-')
                        let hour = horarioParada(x)
                        paradas.push({
                                    lat: x.attributes.lat,
                                    lon: x.attributes.lon,
                                    name: name
                        })
    })
    
   
    let puntos = []
    let init = null;
    let fin = null;
    points.map(x => {
                        if (!init)
                        {
                            init = [x.attributes.lat, x.attributes.lon]
                        }
                        puntos.push([x.attributes.lat, x.attributes.lon])
                        fin = [x.attributes.lat, x.attributes.lon]
                    })

    return { puntos, init, fin, paradas };
  }
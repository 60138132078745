import React, { useState, useEffect } from "react";

const Context = React.createContext({});

export const PositionContextProvider = ({ children }) => {
  const [position, setPosition] = useState({});
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    if (!"geolocation" in navigator) {
      setEnabled(false);
     
    } else {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        setPosition({
          lat: coords.latitude,
          lng: coords.longitude,
        });
        setEnabled(true);
      });
    }
  }, []);

  return (
    <Context.Provider value={{ position, enabled }}>
      {children}
    </Context.Provider>
  );
};

export default Context;

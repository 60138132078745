import { useEffect, useState } from "react";

export function usePosition() {

  const [position, setPosition] = useState(null);
  const [awaitPosition, setAwaitPosition] = useState(true);
  const [errorPosition, setErrorPosition] = useState(false);
  //const [register, setRegister] = useState(false);

  function getPosition() {
    //setLoading(true);
    //setPosition(null);
   /* setAwaitPosition(true);
    setErrorPosition(false);*/

    if ("geolocation" in navigator) {

      
      navigator.geolocation.watchPosition(({ coords }) => {
        setPosition({
          lat: coords.latitude,
          lng: coords.longitude,
        });
        setErrorPosition(false)
        setAwaitPosition(false);
        //setRegister(true);
      });

      /*navigator.geolocation.getCurrentPosition(({ coords }) => {
        setPosition({
          lat: coords.latitude,
          lng: coords.longitude,
        });
        setErrorPosition(false)
        setAwaitPosition(false);
        //setRegister(true);
      });*/
    }
    else{
      setAwaitPosition(false);
      setErrorPosition(true)
    }
  }
  useEffect(() => {
    getPosition();
  }, []);

  return { position, getPosition, awaitPosition, errorPosition };
}

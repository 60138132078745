import axios from "axios";

const url = process.env.REACT_APP_MB_ARRIBO_BACK_BASE_URL; //http://traficonuevo.masterbus.net/api/v1/servicios/proximos

export function getServices() {
  return axios.get(`${url}`);
}

export async function getPosition(interno) {
  const data = axios.get(`${url}/unidades/posicion/${interno}`);
  return data;
}

export function getDataFromBack(object) {
  const data = axios.post(`${url}`, object);
  return data;
}

export function getPositionClient() {
  let position = {};
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      position = {
        lat: coords.latitude,
        lng: coords.longitude,
      };
     
    });

    return position;
  }
  return position;
}

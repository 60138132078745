import  Container from "react-bootstrap/Container";

import AppBar from "./components/toollbar";

import SelectServices from "./components/SelectServices";

import 'bootstrap/dist/css/bootstrap.min.css';

import { PositionContextProvider } from "./context/PositionContext";

import PwaInstallPopupIOS from 'react-pwa-install-ios';


function App()
{
  //            <PositionContextProvider>
  //</PositionContextProvider>
  return (
          <>
    <PwaInstallPopupIOS 
      delay={3}
      lang="en">
    </PwaInstallPopupIOS>  
              <AppBar />
              <Container>
                <SelectServices />
              </Container>          
              
          </> 
  );
}

export default App;
